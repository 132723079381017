import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "src/layout/layout"
import SEO from "src/components/seo"
import PageWrapper from "src/components/page-wrapper"
import { Typography } from "@material-ui/core"
import TermsOfUseContent from "src/content/terms-of-use.mdx"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
    paddingTop: "84px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "36px",
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
})

export default function TermsOfUse({ pageContext, location }) {
  const classes = useStyles()

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    "-",
    " "
  )
  return (
    <>
      <Layout location={location}>
        <SEO title="Terms of Use" />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.mainContainer}>
              <div className={classes.mainContentContainer}>
                <TermsOfUseContent />
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
    </>
  )
}
