import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Terms of Use",
  "lang": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Terms of Use`}</h1>
    <p><strong parentName="p">{`PLEASE READ THESE TERMS OF USE BEFORE USING THE WEBSITE.`}</strong></p>
    <p><strong parentName="p">{`Acceptance of the Terms of Use`}</strong></p>
    <p>{`These terms of use are entered into by and between you and DP Aerogels (the "Company", “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of any website published by the Company, including, but not limited to, any content, functionality, and services offered on or through dpaerogels.com (the “Website”).`}</p>
    <p>{`Please read the Terms of Use carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use in addition to:`}</p>
    <ul>
      <li parentName="ul">{`our `}<a parentName="li" {...{
          "href": "/privacy-policy/"
        }}>{`Privacy Policy`}</a>{`, incorporated herein by
reference.`}</li>
    </ul>
    <p>{`If you do not agree to these Terms of Use, you must not access or use the Website.`}</p>
    <p><strong parentName="p">{`Who May Use the Website`}</strong></p>
    <p>{`The Website are offered and available to users who are 13 years of age or older. The Website are not intended for children under 13 years of age. By using the Website, you represent and warrant that you (i) are 13 years of age or older, (ii) are not barred to use the Website under any applicable law, and (iii) are using the Website only for your own personal use. If you do not meet these requirements, you must not access or use the Website.`}</p>
    <p><strong parentName="p">{`Changes to the Terms of Use`}</strong></p>
    <p>{`We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them.`}</p>
    <p>{`Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.`}</p>
    <p><strong parentName="p">{`Trademarks`}</strong></p>
    <p>{`DP Aerogels name, the terms DP Aerogels International, the DP Aerogels logo and all related names, logos, product and service names, designs and slogans are trademarks of the DP Aerogels or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.`}</p>
    <p><strong parentName="p">{`Prohibited Uses`}</strong></p>
    <p>{`You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:`}</p>
    <ul>
      <li parentName="ul">{`In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries);`}</li>
      <li parentName="ul">{`For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise;`}</li>
      <li parentName="ul">{`To send, knowingly receive, upload, download, use, or re-use any material which does not comply with these Terms of Use;`}</li>
      <li parentName="ul">{`To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation;`}</li>
      <li parentName="ul">{`To impersonate or attempt to impersonate the Company, a Foundation employee, another user, or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing); and`}</li>
      <li parentName="ul">{`To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.`}</li>
    </ul>
    <p>{`Additionally, you agree not to:`}</p>
    <ul>
      <li parentName="ul">{`Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website;`}</li>
      <li parentName="ul">{`Use any robot, spider, or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website;`}</li>
      <li parentName="ul">{`Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent;`}</li>
      <li parentName="ul">{`Use any device, software or routine that interferes with the proper working of the Website;`}</li>
      <li parentName="ul">{`Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful;`}</li>
      <li parentName="ul">{`Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server(s) on which the Website is stored, or any server, computer or database connected to the Website;`}</li>
      <li parentName="ul">{`Attack the Website via a denial-of-service attack or a distributed denial-of-service attack; and`}</li>
      <li parentName="ul">{`Otherwise attempt to interfere with the proper working of the Website.`}</li>
    </ul>
    <p><strong parentName="p">{`Changes to the Website`}</strong></p>
    <p>{`We may update the content on the Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.`}</p>
    <p><strong parentName="p">{`Information About You and Your Visits to the Website`}</strong></p>
    <p>{`All information we collect on the Website is subject to our `}<a parentName="p" {...{
        "href": "/privacy-policy/"
      }}>{`Privacy
Policy`}</a>{`. By using the Website, you consent to all actions that may be taken by us with respect to your information in compliance with the Privacy Policy.`}</p>
    <p><strong parentName="p">{`Linking to the Website and Social Media Features`}</strong></p>
    <p>{`You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.`}</p>
    <p><strong parentName="p">{`Disclaimer of Warranties`}</strong></p>
    <p>{`You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.`}</p>
    <p>{`YOUR USE OF THE WEBSITE, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, THEIR CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER(S) THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.`}</p>
    <p>{`THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.`}</p>
    <p>{`SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRANTIES OR LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU IN THEIR ENTIRETIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.`}</p>
    <p><strong parentName="p">{`Limitation on Liability`}</strong></p>
    <p>{`IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITE LINKED TO THEM, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW WHICH MAY INCLUDE FRAUD.`}</p>
    <p><strong parentName="p">{`Indemnification`}</strong></p>
    <p>{`You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, any use of the Website’ content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.`}</p>
    <p><strong parentName="p">{`Governing Law and Jurisdiction`}</strong></p>
    <p>{`All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of Singapore without giving effect to any choice or conflict of law provision or rule (whether of Singapore or any other jurisdiction).`}</p>
    <p><strong parentName="p">{`Waiver and Severability`}</strong></p>
    <p>{`No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.`}</p>
    <p>{`If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.`}</p>
    <p><strong parentName="p">{`Entire Agreement`}</strong></p>
    <p>{`The Terms of Use, our Privacy Policy and terms of conditions for the registration of events constitute the sole and entire agreement between you and the Company with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.`}</p>
    <p><strong parentName="p">{`Your Comments and Concerns`}</strong></p>
    <p>{`The Website is operated by the Company. All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: `}<a parentName="p" {...{
        "href": "mailto:info@dpaerogels.com"
      }}>{`info@dpaerogels.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      